






























import Vue from "vue";

export default Vue.extend({
  name: "AddDeliveryInfo",
  props: {
    onClose: {
      type: Function,
      default: () => true,
    },
    lang: {
      type: String
    }
  },
});
