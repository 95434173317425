

























































































































































































import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { leftArrowSVG } from "@/services/svg";
import EasyInput from "@/components/common/EasyInput.vue";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
import AddDeliveryInfo from "./AddDeliveryInfo.vue";
import AddDeliverySuccess from "./AddDeliverySuccess.vue";

import { createRequest } from "@/services/api-connector";
import eventsRegister from "@/services/events-register";
import {
  DeliveryItem,
  getDeliveryRequestData,
  logEventFA,
  logEventFB,
} from "@/utils/utils";
import { FacebookParams } from "@/utils/fb-events";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AddDelivery",
  components: {
    EasyInput,
    EasyBottomSheet,
    AddDeliveryInfo,
    AddDeliverySuccess,
  },
  data() {
    return {
      leftArrowSVG,
      isCreated: false,
      isLoading: false,
      isOpened: false,
      selectedCountry: 287,
      trackingNumber: null,
      productList: [] as DeliveryItem[],
      isDeliverySubmited: false,
      isBottomSheetOpened: false,

      editProductIndex: null as number | null,
      productData: {} as DeliveryItem,
      isProductSubmited: false,

      isInfoOpened: false,
      customTokens: {
        mask: "NNNNN",
        tokens: {
          N: { pattern: /^[0-9]*\.?[0-9]*$/ },
        },
      },
      warehousesList: {
        284: {
          Object_id: 1113648,
          State_id: -5001,
          CalcName: "New Jersey warehouse",
          pr_389: null,
          pr201: 284,
          pr493: {
            title: "USA",
            name: "Maria Easyget",
            Firstname: "Maria",
            Surname: "Easyget",
            streetAddress: "100 Naylon Ave",
            city: "Livingston",
            state: "New Jersey (NJ)",
            postCode: "07039",
            phone: "+19732512634",
          },
          pr201_iso: "US",
          pr493_str:
            "07039 New Jersey (NJ), Livingston, 100 Naylon Ave#EG2805956",
        },
        287: {
          Object_id: 1113643,
          State_id: -5001,
          CalcName: "Shenzhen warehouse",
          pr_389:
            "502-503 Room, 5 Floor , 1st Storage.Fengzeyuan Storage Logistic Center, Shahe West Road, Nanshan District",
          pr201: 287,
          pr493: {
            title: "China",
            name: "Ken (叶东仪)",
            streetAddress: "西乡街道三围航空路30号同安物流园A栋418",
            district: "宝安区",
            city: "深圳市",
            state: "广东省",
            phone: "+8613510249872",
          },
          pr201_iso: "CN",
          pr493_str:
            "广东省, 深圳市, 西乡街道三围航空路30号同安物流园A栋418#EG2805956",
        },
        // 294: {
        //   Object_id: 2442082,
        //   State_id: -5001,
        //   CalcName: "Istambul warehouse",
        //   pr_389:
        //     "502-503 Room, 5 Floor , 1st Storage.Fengzeyuan Storage Logistic Center, Shahe West Road, Nanshan District",
        //   pr201: 287,
        //   pr493: {
        //     title: "China",
        //     name: "Ken (叶东仪)",
        //     streetAddress: "西乡街道三围航空路30号同安物流园A栋418",
        //     district: "宝安区",
        //     city: "深圳市",
        //     state: "广东省",
        //     phone: "+8613510249872",
        //   },
        //   pr201_iso: "TR",
        // },
      },
    };
  },
  computed: {
    ...mapGetters("user", ["userId", "user"]),
    ...mapGetters("root", ["lang"]),
    isNewItemValid(): boolean {
      return (
        !!this.productData.name &&
        !!this.productData.count &&
        !!this.productData.price
      );
    },
    isDeliveryValid(): boolean {
      return !!this.trackingNumber && !!this.productList.length;
    },
    isEdit(): boolean {
      return this.editProductIndex !== null;
    },
    priceAll(): number {
      return this.productList.reduce((prev, cur) => prev + (cur.price || 0), 0);
    },
    quantityAll(): number {
      return this.productList.reduce((prev, cur) => prev + (cur.count || 0), 0);
    },
    activeWarehouse(): any {
      return this.warehousesList[this.selectedCountry];
    },
  },
  methods: {
    initEdit(index: number) {
      this.editProductIndex = index;
      this.isBottomSheetOpened = true;
      this.productData = { ...this.productList[this.editProductIndex] };
    },
    initAdd() {
      this.editProductIndex = null;
      this.isBottomSheetOpened = true;
      this.productData = {};
    },
    onEdit() {
      this.isProductSubmited = true;
      if (this.isNewItemValid) {
        this.productList = this.productList.map((item, index) =>
          index === this.editProductIndex ? this.productData : item
        );
        this.isBottomSheetOpened = false;
        this.isProductSubmited = false;
      }
    },
    onAdd() {
      this.isProductSubmited = true;
      if (this.isNewItemValid) {
        this.productList = [...this.productList, this.productData];
        this.isBottomSheetOpened = false;
        this.isProductSubmited = false;
      }
    },
    onRemove(index: number) {
      this.productList = this.productList.filter((item, i) => {
        return i !== index;
      });
    },
    onError(message: TranslateResult) {
      this.$root.$emit(eventsRegister.addMessage, {
        type: "error",
        message,
      });
    },

    onBack() {
      this.reset();
    },

    toggleBottomSheet(val: boolean) {
      this.isBottomSheetOpened = val;
      this.isProductSubmited = false;
      this.productData = {};
    },

    onSubmit() {
      this.isDeliverySubmited = true;
      if (!this.productList.length) {
        this.onError(this.$t("addDeliveryErrorLength"));
      }

      if (this.isDeliveryValid) {
        this.saveNewPackage();
      }
    },

    reset() {
      this.$router.push("/");
      this.isCreated = false;
      this.trackingNumber = null;
      this.productList = [];
      this.isDeliverySubmited = false;
      this.isProductSubmited = false;
    },

    saveNewPackage() {
      this.isLoading = true;

      const data = getDeliveryRequestData(
        this.trackingNumber,
        this.activeWarehouse,
        this.productList,
        this.userId,
        this.user.pr201
      );

      createRequest(
        "objEdit",
        data,
        () => {
          this.$root.$emit(eventsRegister.updateParcels);
          this.isCreated = true;
          this.isLoading = false;
          this.sendSuccessEvents();
        },
        () => (this.isLoading = false)
      );
    },

    sendSuccessEvents() {
      const contentList = this.productList.map((product) => ({
        [FacebookParams.NUM_ITEMS]: product.count,
        [FacebookParams.CURRENCY]: "USD",
        valueToSum: product.price,
      }));
      logEventFA("add_to_cart", {
        item_category: this.activeWarehouse.CalcName,
        price: this.priceAll,
        currency: "USD",
        quantity: this.quantityAll,
        customer_id: this.userId,
      });
      logEventFB(
        "EVENT_NAME_ADDED_TO_CART",
        { CONTENT_TYPE: "delivery", CONTENT: contentList, CURRENCY: "USD" },
        this.priceAll
      );
    },
  },
});
