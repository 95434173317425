















import Vue from "vue";

export default Vue.extend({
  name: "AddDeliverySuccess",
  props: {
    onClose: {
      type: Function,
      default: () => true,
    },
  },
});
